<template>
  <div class="select-cars">
    <input
        class="hide check-select-show" type="checkbox"
        name="auction" id="estimateCar" v-model="showPlate"
    >
    <label class="label size18 bold c-pointer" for="estimateCar">{{ $t('Оценить по гос. номеру') }}</label>
    <div class="view-item">
      <form @submit.prevent="submit">
        <Loader />
        <div class="set-num">
          <input type="text" :placeholder="$t('Введите гос. номер')" v-model="plateNumber" maxlength="8" required/>
        </div>
        <button class="button large size16" v-if="blockId === 1">{{ $t('Оценить стоимость авто') }}</button>
        <button class="button large size16" v-else>{{ $t('Продать за 24 часа') }}</button>
      </form>
    </div>
  </div>
</template>
<script>
import {mapGetters, mapActions, mapMutations} from 'vuex';
import Loader from '../common/Loader.vue';

export default {
  name: 'PlateNumber',
  components: {Loader},
  data(){
    return {
      showPlate: true,
      initialized: false,
    }
  },
  watch: {
    'userAdvertisements.count'() {
      if (!this.initialized) {
        this.initialized = true;
        if (this.userAdvertisements.count && this.isMobile) {
          this.showPlate = false;
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      _plateNumber: 'autoRia24/plateNumber',
      blockId: 'autoRia24/blockId',
      userAdvertisements: 'autoRia24/userAdvertisements',
    }),
    plateNumber: {
      get(){
        return this._plateNumber;
      },
      set(value){
        return this.setPlate(this.preparePlateNumber(value));
      }
    }
  },
  methods: {
    ...mapMutations({
      setPlate: 'autoRia24/setPlate',
      setLoader: 'autoRia24/setLoader'
    }),
    ...mapActions({
      autofill: 'autoRia24/autofill'
    }),
    preparePlateNumber: function (plateNumber) {
      const pattern = [/Е/i, /Т/i, /І/i, /О/i, /А/i, /Н/i, /К/i, /С/i, /В/i, /М/i, /Р/i, /Х/i];
      const replacement = ["E", "T", "I", "O", "A", "H", "K", "C", "B", "M", "P", "X"];
      return pattern
          .reduce(function (result, value, index) {
            return result.replace(value, replacement[index]);
          }, plateNumber)
          .toUpperCase()
          .replace(/\s/gi, '')
          .trim();
    },
    submit(e) {
      return this.$router.push(this.langPrefix + '/24/' + this.plateNumber)
    }
  },
  i18n: {
    messages: {
      ru: {
        'Оценить стоимость авто': 'Оценить стоимость авто',
        'Оценить по гос. номеру': 'Оценить по гос. номеру',
        'Введите гос. номер': 'Введите гос. номер',
        'Продать за 24 часа': 'Продать за 24 часа',
      },
      uk: {
        'Оценить стоимость авто': 'Оцінити вартість авто',
        'Оценить по гос. номеру': 'Оцінити по держ. номеру',
        'Введите гос. номер': 'Введіть держ. номер',
        'Продать за 24 часа': 'Продати за 24 години',
      }
    }
  }
}
</script>
