<template>
  <div class="how-work">
    <h3 class="h3 size24 bold">{{$t('Как это работает')}}?</h3>
    <ul class="unstyle how-work-list">
      <li class="item">
        <div class="custom-title size18 bold">
          <svg width="37" height="36" viewBox="0 0 37 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0)">
              <path d="M5.01172 15H8.4582C8.49164 15 8.52286 14.9833 8.54141 14.9555L11.918 9.8906C12.2889 9.3342 12.9134 9 13.5821 9H27.4413C28.1101 9 28.7345 9.3342 29.1054 9.8906L32.482 14.9555C32.5006 14.9833 32.5318 15 32.5652 15H36.0117C36.2879 15 36.5117 15.2239 36.5117 15.5V16.5C36.5117 16.7761 36.2879 17 36.0117 17H34.5117V30C34.5117 30.5523 34.064 31 33.5117 31H29.5117C28.9594 31 28.5117 30.5523 28.5117 30V27H12.5117V30C12.5117 30.5523 12.064 31 11.5117 31H7.51172C6.95943 31 6.51172 30.5523 6.51172 30V17H5.01172C4.73558 17 4.51172 16.7761 4.51172 16.5V15.5C4.51172 15.2239 4.73558 15 5.01172 15Z" fill="#256799" fill-opacity="0.2"/>
              <path d="M5.37345 11.5102L5.37346 11.5102L8.75004 6.4453C8.9355 6.1671 9.24773 6 9.58209 6H23.4413C23.7757 6 24.0879 6.1671 24.2734 6.4453L27.65 11.5101C27.65 11.5102 27.65 11.5102 27.65 11.5102C27.854 11.8162 28.1975 12 28.5652 12H29.5117V13V26H25.5117V23V22H24.5117H8.51172H7.51172V23V26H3.51172V13V12H4.4582C4.82599 12 5.16944 11.8162 5.37345 11.5102Z" stroke="#256799" stroke-width="2"/>
              <path d="M12.5117 13L16.5117 17L25.0117 8.5" stroke="#256799" stroke-width="2" stroke-linecap="round"/>
            </g>
            <defs>
              <clipPath id="clip0">
                <rect width="36" height="36" fill="white" transform="translate(0.511719)"/>
              </clipPath>
            </defs>
          </svg>
          {{ $t('Оценка автомобиля онлайн') }}</div>
        <div class="text size16">{{ $t('Укажите параметры авто, для  первоначальной on-line оценки авто. Рыночная стоимость вашего авто будет сформирована на основе данных о продажах аналогичных авто на AUTO.RIA') }}</div>
      </li>
      <li class="item">
        <div class="custom-title size18 bold">
          <svg width="37" height="36" viewBox="0 0 37 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.5109 5C21.7072 5 22.8709 5.14005 23.9863 5.40465L24.9499 9.93242C25.6811 10.2553 26.3705 10.6555 27.0078 11.1227L31.4096 9.69367C33.0028 11.3778 34.2098 13.4311 34.889 15.7117L31.4475 18.8121C31.4894 19.2023 31.5109 19.5986 31.5109 20C31.5109 20.4014 31.4894 20.7977 31.4475 21.188L34.889 24.2883C34.2098 26.569 33.0027 28.6222 31.4095 30.3064L27.0077 28.8774C26.3705 29.3445 25.6811 29.7447 24.9499 30.0676L23.9863 34.5953C22.8709 34.8599 21.7073 35 20.5109 35C19.3145 35 18.1508 34.8599 17.0354 34.5953L16.0718 30.0675C15.3406 29.7447 14.6512 29.3445 14.014 28.8773L9.61227 30.3063C8.01906 28.6222 6.81202 26.5689 6.13282 24.2882L9.57427 21.188C9.53236 20.7977 9.51086 20.4014 9.51086 20C9.51086 19.5986 9.53236 19.2023 9.57426 18.8121L6.13281 15.7118C6.812 13.4312 8.01903 11.3779 9.61222 9.69371L14.0139 11.1227C14.6512 10.6555 15.3406 10.2553 16.0718 9.93244L17.0354 5.40469C18.1509 5.14006 19.3145 5 20.5109 5Z" fill="#256799" fill-opacity="0.2"/>
            <circle cx="16.5117" cy="16" r="4" stroke="#256799" stroke-width="2"/>
            <path d="M19.9718 6.14058L20.0782 6.64068L20.5459 6.84721C21.2103 7.14057 21.837 7.50433 22.4165 7.92919L22.8295 8.23194L23.3166 8.07383L27.1027 6.84466C28.2498 8.17049 29.1517 9.71313 29.7387 11.4021L26.7781 14.0691L26.3986 14.411L26.4532 14.9188C26.4913 15.2737 26.5109 15.6344 26.5109 16C26.5109 16.3656 26.4913 16.7264 26.4532 17.0812L26.3986 17.5891L26.7781 17.931L29.7387 20.598C29.1516 22.2869 28.2498 23.8296 27.1027 25.1554L23.3165 23.9262L22.8295 23.7681L22.4165 24.0709C21.837 24.4957 21.2103 24.8594 20.5459 25.1528L20.0782 25.3593L19.9718 25.8594L19.1431 29.7531C18.2913 29.9151 17.4114 30 16.5109 30C15.6103 30 14.7305 29.9151 13.8785 29.7531L13.0499 25.8594L12.9435 25.3593L12.4757 25.1528C11.8114 24.8594 11.1847 24.4957 10.6052 24.0708L10.1923 23.7681L9.70522 23.9262L5.9191 25.1553C4.77198 23.8295 3.87014 22.2868 3.28314 20.5979L6.24358 17.931L6.62309 17.5891L6.56855 17.0812C6.53045 16.7264 6.51086 16.3656 6.51086 16C6.51086 15.6344 6.53045 15.2737 6.56855 14.9188L6.62308 14.411L6.24358 14.0691L3.28313 11.4022C3.87012 9.7132 4.77195 8.17055 5.91906 6.8447L9.70515 8.07384L10.1922 8.23196L10.6052 7.9292C11.1847 7.50434 11.8114 7.14059 12.4758 6.84723L12.9435 6.64069L13.0499 6.14059L13.8786 2.2469C14.7305 2.0849 15.6104 2 16.5109 2C17.4114 2 18.2913 2.08489 19.1431 2.24688L19.9718 6.14058Z" stroke="#256799" stroke-width="2"/>
          </svg>
          {{ $t('Визит на бесплатную проверку авто') }}</div>
        <div class="text size16">{{ $t('Технические специалисты проведут детальный осмотр и проверку вашего автомобиля и предложат рыночную цену') }} </div>
      </li>
      <li class="item">
        <div class="custom-title size18 bold">
          <svg width="37" height="36" viewBox="0 0 37 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="20.5117" cy="20" r="14" fill="#256799" fill-opacity="0.2"/>
            <circle cx="16.5117" cy="16" r="13" stroke="#256799" stroke-width="2"/>
            <path d="M20.5117 12C20.5117 9.5 19.0117 8 16.5117 8C14.0117 8 12.5117 9.5 12.5117 12C12.5117 14.5 14.0117 16 16.5117 16C19.0117 16 20.5117 17.5 20.5117 20C20.5117 22.5 19.0117 24 16.5117 24C14.0117 24 12.5117 22.5 12.5117 20" stroke="#256799" stroke-width="2" stroke-linecap="round"/>
            <path d="M16.5117 7V25" stroke="#256799" stroke-width="2" stroke-linecap="round"/>
          </svg>
          {{ $t('Встреча с партнерами AUTO.RIA 24') }}</div>
        <div class="text size16">{{ $t('AUTO.RIA обеспечит контроль качества сделки - быструю оплату, оформление продажи авто у нотариуса и получение денег') }}</div>
      </li>
    </ul>
    <a href="#plate" class="half-btn btn-blue size16 text-c">{{ $t('Перейти к оценке авто') }}</a>
  </div>
</template>
<script>
export default {
  name: 'HowItWorks',
  i18n: {
    messages: {
      ru: {
        'Как это работает': 'Как это работает',
        'Перейти к оценке авто': 'Перейти к оценке авто',
        'Оценка автомобиля онлайн': 'Оценка автомобиля онлайн',
        'Визит на бесплатную проверку авто': 'Визит на бесплатную проверку авто',
        'Технические специалисты проведут детальный осмотр и проверку вашего автомобиля и предложат рыночную цену': 'Технические специалисты проведут детальный осмотр и проверку вашего автомобиля и предложат рыночную цену',
        'Встреча с партнерами AUTO.RIA 24': 'Встреча с партнерами AUTO.RIA 24',
        'Укажите параметры авто, для первоначальной on-line оценки': 'Укажите параметры авто, для первоначальной on-line оценки',
        'Укажите параметры авто, для первоначальной on-line оценки авто. Рыночна стоимость своего авто будет сформирована на основе данных о продажах аналогичных авто на AUTO.RIA': 'Укажите параметры авто, для первоначальной on-line оценки авто. Рыночна стоимость своего авто будет сформирована на основе данных о продажах аналогичных авто на AUTO.RIA',
        'AUTO.RIA обеспечит контроль качества сделки - быструю оплату, оформление продажи авто у нотариуса и получение денег': 'AUTO.RIA обеспечит контроль качества сделки - быструю оплату, оформление продажи авто у нотариуса и получение денег',
        'Укажите параметры авто, для  первоначальной on-line оценки авто. Рыночная стоимость вашего авто будет сформирована на основе данных о продажах аналогичных авто на AUTO.RIA': 'Укажите параметры авто, для  первоначальной on-line оценки авто. Рыночная стоимость вашего авто будет сформирована на основе данных о продажах аналогичных авто на AUTO.RIA'
      },
      uk: {
        'Как это работает': 'Як це працює',
        'Перейти к оценке авто': 'Перейти до оцінки авто',
        'Оценка автомобиля онлайн': 'Оцініть автомобіль онлайн',
        'Визит на бесплатную проверку авто': 'Візит на безкоштовну перевірку авто',
        'Технические специалисты проведут детальный осмотр и проверку вашего автомобиля и предложат рыночную цену': 'Технічні фахівці проведуть детальний огляд і перевірку вашого автомобіля і запропонують ринкову ціну',
        'Встреча с партнерами AUTO.RIA 24': 'Зустріч з партнерами AUTO.RIA 24',
        'Укажите параметры авто, для первоначальной on-line оценки': 'Вкажіть параметри авто, для первісної on-line оцінки',
        'Укажите параметры авто, для первоначальной on-line оценки авто. Рыночна стоимость своего авто будет сформирована на основе данных о продажах аналогичных авто на AUTO.RIA': 'Вкажіть параметри авто, для первісної on-line оцінки авто. Ринкова вартість свого авто буде сформована на основі даних про продаж аналогічних авто на AUTO.RIA',
        'AUTO.RIA обеспечит контроль качества сделки - быструю оплату, оформление продажи авто у нотариуса и получение денег': 'AUTO.RIA забезпечить контроль якості угоди — швидку оплату, оформлення продажу авто у нотаріуса і отримання грошей',
        'Укажите параметры авто, для  первоначальной on-line оценки авто. Рыночная стоимость вашего авто будет сформирована на основе данных о продажах аналогичных авто на AUTO.RIA': 'Вкажіть параметри авто, для початкової on-line оцінки авто. Ринкова вартість вашого авто буде сформована на основі даних про продажі аналогічних авто на AUTO.RIA'
      }
    }
  }
}
</script>
