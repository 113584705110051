<template>
  <div class="select-cars">
    <Loader/>
    <input type="checkbox" name="auction" id="myCars" checked="checked" class="hide check-select-show">
    <label for="myCars" class="label size18 bold c-pointer">
      {{$t('Мои авто')}}
      <span class="grey">
        {{ userAdvertisements.count }}
      </span>
    </label>

    <div class="carousel buttons-out view-item">
      <a v-if="userAdvertisements.count > itemCount" data-action="prev" class="carousel-control prev" @click="prev"></a>
      <a v-if="userAdvertisements.count > itemCount" data-action="next" class="carousel-control next" @click="next"></a>
      <div class="carousel-inner">
        <div class="row">
          <Item
              :auto="item"
              :key="key"
              v-for="(item, key) in advertisements"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import Loader from '../../common/Loader.vue';

export default {
  name: 'UserAdvertisements',
  props: {
    itemCount: Number
  },
  data() {
    return {
      showItems: true
    }
  },
  methods: {
    ...mapActions({
      fetchUserAdvertisements: 'autoRia24/userAdvertisements',
    }),
    ...mapMutations({
      changePage: 'autoRia24/page',
      setLoader: 'autoRia24/setLoader'
    }),
    prev() {
      this.page--;
    },
    next() {
      this.page++;
    },
    reloadAdvertisement(page){
      this.setLoader(true);
      this
          .fetchUserAdvertisements({page: page || this.page, itemCount: this.itemCount})
          .then(() => this.setLoader(false))
          .catch(() => {
            this.setLoader(false);
          })
      ;
    },
  },
  computed: {
    ...mapGetters({
      userAdvertisements: 'autoRia24/userAdvertisements',
      $page: 'autoRia24/page',
    }),
    page: {
      get() {
        return this.$page;
      },
      set(value) {
        let set = value;
        if (value <= 0) {
          set = this.pageCount;
        }
        if(value >= (this.pageCount + 1)) {
          set = 1;
        }
        return this.changePage(set);
      }
    },
    pageCount() {
      return Math.ceil(this.userAdvertisements.count / this.itemCount);
    },
    advertisements() {
      const { userAdvertisements: {autos = []} = {} } = this
      return autos;
    }
  },
  watch: {
    isMobile(isMobile) {
      if(!isMobile) {
        this.showItems = true;
      }
    },
    itemCount(){
      this.reloadAdvertisement();
    },
    page(page) {
      this.reloadAdvertisement();
    }
  },
  components: {
    Item: () => import('./Item.vue'),
    Loader
  },
  i18n: {
    messages: {
      ru: {
        'Мои авто': 'Мои авто',
      },
      uk: {
        'Мои авто': 'Мої авто'
      }
    }
  }
}
</script>
