<template>
  <div class="app-content m-padding">
    <h1 class="bold size24" ref="toggleAutoSaleButton" v-if="blockId === 1">{{ $t('Оценить стоимость авто') }}!</h1>
    <h1 class="bold size24" v-else>{{ $t('Продайте авто за 24 часа') }}!</h1>
    <h2 class="size16 subtitle">{{ $t('Бесплатная оценка и проверка авто с быстрой выплатой от') }} AUTO.RIA 24</h2>
    <hr>
    <UserAdvertisements v-show="userAdvertisements.count && isMobile" :itemCount="itemCount"/>
    <hr v-show="userAdvertisements.count && isMobile">
    <PlateNumber />
    <hr>
    <HowItWorks />
    <Reviews />
    <About />
    <hr>
    <BecomeAPartner />
    <hr>
    <Contacts />
    <div class="ppc_btn dhide sell_auto"  id="sell_auto" :class="{'fixed': toggleSellAuto, 'hide': !showSellAuto}">
      <a @click="sendClickLog" href="javascript:void(0)" title="Продать авто" aria-label="Продать авто" class="button-add">
          <svg aria-label="Продать авто" class="svg svg-add-deck"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg_header_add_deck"></use></svg>
        {{$t('Продать авто')}}</a>
    </div>
  </div>
</template>
<script>
const storeModule = require('../../../store/autoRia24');
const { mapGetters, mapActions } = require('vuex');
const { get, set } = require('../../../helpers/cookie');
export default {
  data() {
    return {
      toggleSellAuto: false,
      showSellAuto: false,
      fbclid: false,
      gclid: false
    }
  },
  name: 'AutoRia24',
  mixins: [require('../../../mixins/AutoRia24.logger')],
  init({store, route, context}) {
    // context.response.redirect("/news/auto/227689/chto-takoe-avtovykup-i-kakuyu-mashinu-mozhno-prodat.html")
    const { to: { query: { blockId = 1 } = {} } = {} } = {} = route;

    if (!route.from || route.from.path.indexOf('/24') !== 0) {
      store.registerModule('autoRia24', storeModule);
    }
    store.dispatch('autoRia24/clearForm', '');
    store.dispatch('autoRia24/blockId', Number(blockId));
  },
  computed: {
    ...mapGetters({
      userAdvertisements: 'autoRia24/userAdvertisements',
      page: 'autoRia24/page',
      blockId: 'autoRia24/blockId',
    }),
    itemCount() {
      return this.isMobile ? 1 : 3;
    }
  },
  methods: {
    ...mapActions({
      fetchUserAdvertisements: 'autoRia24/userAdvertisements',
    }),
    sendClickLog(){
      this.sendInitLog({
        action: 'init',
        page: 'sellButton24',
        push_button: 1,
        screentype: this.isDesktop ? 1 : 2,
        fbclid: Boolean(this.fbclid),
        gclid: Boolean(this.gclid),
      });

      window.location.href = this.langPrefix + '/add_auto.html';
    },
  },
  mounted() {
    if (_TARGET_ == 'client') {
      const {query: {blockId = 0} = {}} = {} = this.$route;
      this.sendInitLog({
        action: 'init',
        page: '/24/',
        langId: this.langId,
        blockId,
        screenType: this.isMobile ? 'mobile' : 'desktop'
      })

      this.fbclid = this.$route.query.fbclid;
      this.gclid = this.$route.query.gclid;

      if((this.fbclid || this.gclid) && !get('sell_auto_button_24')){
        set('sell_auto_button_24', 1, { 'max-age': 60*60*24*7 });
      }

      try {
        if(get('sell_auto_button_24')){
          this.showSellAuto = true;

          this.sendInitLog({
            action: 'init',
            page: 'sellButton24',
            push_button: 0,
            screentype: this.isDesktop ? 1 : 2,
            fbclid: Boolean(this.fbclid),
            gclid: Boolean(this.gclid),
          });

          const _this = this;
          const IOW = require('../../../helpers/IntersectionObserver_screen__01');
          IOW.add(this.$refs.toggleAutoSaleButton, (entry = {}) => {
            if (entry && entry.isIntersecting) {
              _this.toggleSellAuto = false;
            } else {
              _this.toggleSellAuto = true;
            }
          });
        }
      } catch (error) {
        _this.toggleSellAuto = false;
        console.error(error);
      }

      this.fetchUserAdvertisements({ itemCount: this.itemCount, page: this.page });
    }
  },
  metaInfo() {
    const _seo = seo[this.langId];
    return {
      title: _seo.title,
      meta: [
        { name: 'ROBOTS', content: 'NOINDEX, NOFOLLOW' },
        { name: 'description', content: _seo.description },
        { property: 'og:site_name', content: 'AUTO.RIA' },
        { property: 'og:description', content: _seo.description },
        { property: 'og:url', content: `https://auto.ria.com${this.langPrefix}/24/` },
        { property: 'og:title', content: _seo.title},
        { property: 'og:image', content: 'https://cdn.riastatic.com/docs/pictures/common/1/1/12344/12344.png' },
        { property: 'og:type', content: 'article'},
        { property: 'fb:app_id', content: '214702429075634'},
      ],
      link: [
        {rel: 'alternate', hreflang: 'ru-UA', href: 'https://auto.ria.com/24/'},
        {rel: 'alternate', hreflang: 'uk-UA', href: 'https://auto.ria.com/uk/24/'},
        {rel: 'alternate', hreflang: 'ru', href: 'https://auto.ria.com/24/'},
        {rel: 'alternate', hreflang: 'uk', href: 'https://auto.ria.com/uk/24/'},
        {rel: 'alternate', hreflang: 'x-default', href: 'https://auto.ria.com/24/'},
        {rel: 'canonical', href: "https://auto.ria.com/24/"}
      ]
    };
  },
  components: {
    PlateNumber: () => import('./PlateNumber.vue'),
    HowItWorks: () => import('./HowItWorks.vue'),
    About: () => import('./About.vue'),
    Contacts: () => import('../common/Contacts.vue'),
    BecomeAPartner: () => import('./BecomeAPartner.vue'),
    Reviews: () => import('./Reviews.vue'),
    UserAdvertisements: () => import('./UserAdvertisements/index.vue')
  },
  i18n: {
    messages: {
      ru: {
        'Оценить стоимость авто': 'Оценить стоимость авто',
        'Продайте авто за 24 часа': 'Продайте авто за 24 часа',
        'Бесплатная оценка и проверка авто с быстрой выплатой от': 'Бесплатная оценка и проверка авто с быстрой выплатой от',
        'Попробуйте уже сейчас': 'Попробуйте уже сейчас',
        'Продажа авто за 24 часа напрямую от': 'Продажа авто за 24 часа напрямую от',
        'За сутки выкупим ваше авто после бесплатного осмотра и оценки по устраивающей вас цене.': 'За сутки выкупим ваше авто после бесплатного осмотра и оценки по устраивающей вас цене.',
        'Продать авто': 'Продать авто',
      },
      uk: {
        'Оценить стоимость авто': 'Оцінити вартість авто',
        'Продайте авто за 24 часа': 'Продайте авто за 24 години',
        'Бесплатная оценка и проверка авто с быстрой выплатой от': 'Безкоштовна оцінка та перевірка авто зі швидкою виплатою від',
        'Попробуйте уже сейчас': 'Спробуйте вже зараз',
        'Продажа авто за 24 часа напрямую от': 'Продаж авто за 24 години безпосередньо від',
        'За сутки выкупим ваше авто после бесплатного осмотра и оценки по устраивающей вас цене.': 'За добу викупимо ваше авто після безкоштовного огляду і оцінки по влаштовує вас ціною.',
        'Продать авто': 'Продати авто',
      }
    }
  }

}

var seo = {
  2: {
    title: 'AUTO.RIA 24 – лучше чем автовыкуп',
    description: 'Программа AUTO.RIA 24 поможет выкупить ваш автомобиль за короткий срок и вменяемые деньги. Всего три шага: заявка, встреча, сделка'
  },
  4: {
    title: 'AUTO.RIA 24 – краще за автовикуп',
    description: 'Програма AUTO.RIA 24 допоможе викупити ваш автомобіль за короткий термін й гідні кошти. Всього три кроки: заявка, зустріч, угода'
  }
}
</script>
<style lang="sass" src="../styles.sass"/>
