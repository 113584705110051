<template>
  <div>
    <h5 class="size18 bold">{{ $t('Как стать партнером AUTO.RIA 24') }}</h5>
    <p style="margin-bottom: 5px"> {{ $t('C нами уже') }} {{ partnersCount }} {{ $tc('партнер', partnersCount) }} {{ $t('с') }} 22 {{ $t('областей Украины') }}. {{ $t('Присоединяйтесь и получите эксклюзивный доступ к еще не опубликованным на AUTO.RIA предложениям') }}</p>
    <a :href="link">{{ $t('Узнайте детали сотрудничества') }} →</a>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: 'BecomeAPartner',
  computed: {
    ...mapGetters({
      langPrefix: 'lang/prefix',
      partnersCount: 'autoRia24/partnersCount',
    }),
    link() {
      return `${this.langPrefix}/24/partners`
    }
  },
  mounted() {
    this.getPartnersCount();
  },
  methods: {
    ...mapActions({
      getPartnersCount: 'autoRia24/partnersCount',
    })
  },
  i18n: {
    messages: {
      ru: {
        'Присоединяйтесь и получите эксклюзивный доступ к еще не опубликованным на AUTO.RIA предложениям': 'Присоединяйтесь и получите эксклюзивный доступ к еще не опубликованным на AUTO.RIA предложениям',
        'Как стать партнером AUTO.RIA 24': 'Как стать партнером AUTO.RIA 24',
        'C нами уже': 'C нами уже',
        'партнер': 'партнер | партнер | партнера | партнеров',
        'с': 'с',
        'областей Украины': 'областей Украины',
        'Узнайте детали сотрудничества': 'Узнайте детали сотрудничества'
      },
      uk: {
        'Присоединяйтесь и получите эксклюзивный доступ к еще не опубликованным на AUTO.RIA предложениям': 'Приєднуйтесь та отримайте ексклюзивний доступ до ще не опублікованих на AUTO.RIA пропозицій',
        'Как стать партнером AUTO.RIA 24': 'Як стати партнером AUTO.RIA 24',
        'C нами уже': 'З нами вже',
        'партнер': 'партнер | партнер | партнера | партнерів',
        'с': 'з',
        'областей Украины': 'областей України',
        'Узнайте детали сотрудничества': 'Дізнайтеся деталі співпраці'
      }
    }
  }
}
</script>
