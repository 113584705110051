<template>
  <div class="span4">
    <a :href="href" :title="title" class="photo-185x120 loaded">
      <img loading="lazy"
           :src="auto.photo.url"
           :title="title"
           :alt="title">
    </a>
    <div class="my-cars-title"><a :href="href">{{ title }}</a></div>
    <button class="button large size16" @click="rate">{{$t('Оценить стоимость')}}</button>
  </div>
</template>
<script>
export default {
  name: 'AdvertisementItem',
  props: {
    auto: Object
  },
  computed: {
    id() {
      const {auto: { id } = {}} = this;
      return id;
    },
    model() {
      const { model = {} } = this.auto || {};
      return model;
    },
    brand() {
      const { brand = {} } = this.auto || {};
      return brand;
    },
    title() {
      const {model, brand, auto, isMobile} = this;
      return `${brand.name} ${model.name} ${auto.year}`;
    },
    href() {
      const {brand, model, id} = this;
      return `/auto_${brand.eng}_${model.eng}_${id}.html`;
    }
  },
  methods: {
    rate() {
      const { id } = this;
      this.$router.push(this.langPrefix + '/24/' + id);
    }
  },
  i18n: {
    messages: {
      ru: {
        'Оценить стоимость': 'Оценить стоимость',
      },
      uk: {
        'Оценить стоимость': 'Оцінити вартість',
      }
    }
  }
}
</script>
