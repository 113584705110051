<template>
  <div class="about">
    <h5 class="size18 bold">{{ $t('Остались вопросы') }}?</h5>

    <div class="mb-30">
      <div class="faq-item">
        <input class="hide check-select-show" type="checkbox" value="1" name="auction" id="faq-1">
        <label class="label size16 bold link" for="faq-1">{{ $t('Какие комиссии и сборы я оплачу при продаже') }}?</label>
        <div class="view-item">
          {{ $t('Никаких. Вы просто продаете авто в рамках озвученной цены и делаете это быстро. Никаких дополнительных расходов и скрытых платежей') }}
        </div>
      </div>
      <div class="faq-item">
        <input class="hide check-select-show" type="checkbox" value="1" name="auction" id="faq-2">
        <label class="label size16 bold link" for="faq-2">{{ $t('Как рассчитывается стоимость моего авто') }}?</label>
        <div class="view-item">
          {{ $t('Прогнозируемая цена рассчитывается в диапазоне 85-95% от рыночной стоимости автомобилей, подобных вашему. Учитываются только основные параметры: марка и модель, пробег, год выпуска, коробка передач, привод, тип топлива, объем двигателя и общее техническое состояние. Не учитывается визуальное состояние авто на фото, описание, цвет и прочее') }}
        </div>
      </div>
      <div class="faq-item">
        <input class="hide check-select-show" type="checkbox" value="1" name="auction" id="faq-3">
        <label class="label size16 bold link" for="faq-3">{{ $t('Но я могу продать и по более высокой цене') }}</label>
        <div class="view-item">
          {{ $t('Самостоятельная продажа – процесс длительный и не ясно, сколько времени он займет. А авто, как известно, со временем только теряет в цене. Авто, которое вы присмотрелись себе на замену, может и не дождаться, пока вы продадите свое нынешнее. Так стоит ли рисковать и сжигать нервы, если все может решиться за сутки') }}?
        </div>
      </div>
      <div class="faq-item">
        <input class="hide check-select-show" type="checkbox" value="1" name="auction" id="faq-4">
        <label class="label size16 bold link" for="faq-4">{{ $t('А если на встрече предложат цену, которая меня не устроит') }}?</label>
        <div class="view-item">
          {{ $t('Программа AUTO.RIA 24 ни к чему вас не обязывает. Это лишь дополнительная возможность для вас продать авто и сделать это быстро. Отказаться можно в любой момент: хоть на встрече, хоть до нее, хоть на этапе переоформления') }}
        </div>
      </div>
    </div>

    <h5 class="size18 bold" id="whatIsAutoRia24">{{ $t('Что такое') }} AUTO.RIA 24?</h5>
    <p>{{ $t('С AUTO.RIA 24 вы можете продать авто за сутки. Сервис позволяет пройти  бесплатную экспертную проверку авто и покупку по рыночной цене. Вы получаете полностью оформленную сделку и деньги всего за 24 часа') }}</p>
  </div>
</template>
<script>
export default {
  name: 'About',
  i18n: {
    messages: {
      ru: {
        'Остались вопросы': 'Остались вопросы',
        'Программа AUTO.RIA 24 ни к чему вас не обязывает. Это лишь дополнительная возможность для вас продать авто и сделать это быстро. Отказаться можно в любой момент: хоть на встрече, хоть до нее, хоть на этапе переоформления': 'Программа AUTO.RIA 24 ни к чему вас не обязывает. Это лишь дополнительная возможность для вас продать авто и сделать это быстро. Отказаться можно в любой момент: хоть на встрече, хоть до нее, хоть на этапе переоформления',
        'Какие комиссии и сборы я оплачу при продаже': 'Какие комиссии и сборы я оплачу при продаже',
        'Как рассчитывается стоимость моего авто': 'Как рассчитывается стоимость моего авто',
        'Никаких. Вы просто продаете авто в рамках озвученной цены и делаете это быстро. Никаких дополнительных расходов и скрытых платежей': 'Никаких. Вы просто продаете авто в рамках озвученной цены и делаете это быстро. Никаких дополнительных расходов и скрытых платежей',
        'С': 'С',
        'Прогнозируемая цена рассчитывается в диапазоне 85-95% от рыночной стоимости автомобилей, подобных вашему. Учитываются только основные параметры: марка и модель, пробег, год выпуска, коробка передач, привод, тип топлива, объем двигателя и общее техническое состояние. Не учитывается визуальное состояние авто на фото, описание, цвет и прочее': 'Прогнозируемая цена рассчитывается в диапазоне 85-95% от рыночной стоимости автомобилей, подобных вашему. Учитываются только основные параметры: марка и модель, пробег, год выпуска, коробка передач, привод, тип топлива, объем двигателя и общее техническое состояние. Не учитывается визуальное состояние авто на фото, описание, цвет и прочее',
        'Что такое': 'Что такое',
        'А если на встрече предложат цену, которая меня не устроит': 'А если на встрече предложат цену, которая меня не устроит',
        'Но я могу продать и по более высокой цене': 'Но я могу продать и по более высокой цене',
        'Самостоятельная продажа – процесс длительный и не ясно, сколько времени он займет. А авто, как известно, со временем только теряет в цене. Авто, которое вы присмотрелись себе на замену, может и не дождаться, пока вы продадите свое нынешнее. Так стоит ли рисковать и сжигать нервы, если все может решиться за сутки': 'Самостоятельная продажа – процесс длительный и не ясно, сколько времени он займет. А авто, как известно, со временем только теряет в цене. Авто, которое вы присмотрелись себе на замену, может и не дождаться, пока вы продадите свое нынешнее. Так стоит ли рисковать и сжигать нервы, если все может решиться за сутки',
        'С AUTO.RIA 24 вы можете продать авто за сутки. Сервис позволяет пройти  бесплатную экспертную проверку авто и покупку по рыночной цене. Вы получаете полностью оформленную сделку и деньги всего за 24 часа': 'С AUTO.RIA 24 вы можете продать авто за сутки. Сервис позволяет пройти  бесплатную экспертную проверку авто и покупку по рыночной цене. Вы получаете полностью оформленную сделку и деньги всего за 24 часа',
        'вы можете продать авто за сутки. Сервис позволяет пройти бесплатную экспертную проверку авто и продажу по рыночной цене. Вы получаете полностью оформленную сделку и деньги всего за 24 часа': 'вы можете продать авто за сутки. Сервис позволяет пройти бесплатную экспертную проверку авто и продажу по рыночной цене. Вы получаете полностью оформленную сделку и деньги всего за 24 часа',
      },
      uk: {
        'Остались вопросы': 'Залишились питання',
        'Программа AUTO.RIA 24 ни к чему вас не обязывает. Это лишь дополнительная возможность для вас продать авто и сделать это быстро. Отказаться можно в любой момент: хоть на встрече, хоть до нее, хоть на этапе переоформления': 'Програма AUTO.RIA24 ні до чого вас не зобов’язує. Це лише додаткова можливість для вас продати авто і зробити це швидко. Відмовитись ви можете в будь-який момент: хоч на зустрічі, хоч до неї, хоч на етапі переоформлення',
        'Какие комиссии и сборы я оплачу при продаже': 'Які комісії та збори я сплачу при продажі',
        'Как рассчитывается стоимость моего авто': 'Як розраховується вартість мого авто',
        'Никаких. Вы просто продаете авто в рамках озвученной цены и делаете это быстро. Никаких дополнительных расходов и скрытых платежей': 'Жодних. Ви просто продаєте авто в рамках озвученої ціни і робите це швидко. Жодних додаткових витрат і прихованих платежів',
        'С': 'З',
        'Прогнозируемая цена рассчитывается в диапазоне 85-95% от рыночной стоимости автомобилей, подобных вашему. Учитываются только основные параметры: марка и модель, пробег, год выпуска, коробка передач, привод, тип топлива, объем двигателя и общее техническое состояние. Не учитывается визуальное состояние авто на фото, описание, цвет и прочее': 'Прогнозована ціна розраховується в діапазоні 85-95% від ринкової вартості автомобілів, подібних до вашого. Враховуються тільки основні параметри: марка/модель, пробіг, рік випуску, коробка передач, привід тип палива, об’єм двигуна і загальний технічний стан. Не враховується візуальний стан авто на фото, опис, колір та інше',
        'Что такое': 'Що таке',
        'А если на встрече предложат цену, которая меня не устроит': 'А якщо на зустрічі запропонують ціну, яка мене не влаштує',
        'Но я могу продать и по более высокой цене': 'Але я можу продати й за вищу ціну',
        'Самостоятельная продажа – процесс длительный и не ясно, сколько времени он займет. А авто, как известно, со временем только теряет в цене. Авто, которое вы присмотрелись себе на замену, может и не дождаться, пока вы продадите свое нынешнее. Так стоит ли рисковать и сжигать нервы, если все может решиться за сутки': 'Самостійний продаж — процес тривалий і не ясно, скільки він займе часу. А авто, як відомо, з часом лише втрачає в ціні. До того ж, авто, яке ви придивились собі на заміну, може й не дочекатись, поки ви продасте своє нинішнє. То чи варто ризикувати й спалювати нерви, якщо все може вирішитись за добу',
        'С AUTO.RIA 24 вы можете продать авто за сутки. Сервис позволяет пройти  бесплатную экспертную проверку авто и покупку по рыночной цене. Вы получаете полностью оформленную сделку и деньги всего за 24 часа': 'З AUTO.RIA 24 ви можете продати авто за добу. Сервіс дозволяє пройти безкоштовну експертну перевірку авто і купівлю за ринковою ціною. Ви отримуєте повністю оформлену угоду і гроші всього за 24 години',
        'вы можете продать авто за сутки. Сервис позволяет пройти бесплатную экспертную проверку авто и продажу по рыночной цене. Вы получаете полностью оформленную сделку и деньги всего за 24 часа': 'ви можете продати авто за добу. Сервіс дозволяє пройти безкоштовну експертну перевірку авто і продаж за ринковою ціною. Ви отримуєте повністю оформлену угоду і гроші всього за 24 години'
      }
    }
  }
}
</script>
