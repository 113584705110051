<template>
  <div v-if="currentItem.text" class="without-hr">
    <h5 class="size18 bold">{{ $t('Отзывы о программе') }} <span class="i-block">AUTO.RIA 24</span></h5>
    <div class="carousel">
      <span><a class="carousel-control prev" data-action="prev" @click="setPrev" /></span>
      <span><a class="carousel-control next" data-action="next" @click="setNext" /></span>
      <div class="carousel-inner">
        <div class="review-item">
          <div class="avatar"><img alt="" width="48" :src="currentItem.user.photo" class="m-auto"/></div>
          <div class="review-wrap">
            <div class="bold">{{ currentItem.user.name }}</div>
            <div class="review-info green size13">{{ $t('Подтвержденная сделка')}}</div>
            {{ currentItem.text }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters} from 'vuex';

export default {
  name: 'Reviews',
  methods: {
    ...mapActions({
      fetchReviews: 'autoRia24/reviews',
    }),
    setNext(){
      this.index++;
      if (this.index >= this.reviews.length) {
        this.index = 0;
      }
    },
    setPrev() {
      this.index--;
      if (this.index < 0) {
        this.index = this.reviews.length - 1;
      }
    }
  },
  data() {
    return {
      index: 0,
    }
  },
  computed: {
    ...mapGetters({
      reviews: 'autoRia24/reviews'
    }),
    currentItem() {
      return this.reviews[this.index] || {};
    }
  },
  mounted() {
    this.fetchReviews()
  },
  i18n: {
    messages: {
      ru: {
        'Отзывы о программе': 'Отзывы о программе',
        'Подтвержденная сделка': 'Подтвержденная сделка',
      },
      uk: {
        'Отзывы о программе': 'Відгуки про програму',
        'Подтвержденная сделка': 'Підтверджена угода',
      }
    }
  }
}
</script>
